<template>
  <main id="register">
    <div class="container">
      <form class="form-horizontal mb20" @submit.prevent="register">
        <div class="row">
          <div class="col-sm-12">
            <div class="panel">
              <div class="panel-heading">
                <h2 class="pageTitle">
                  登録内容の確認<span>confirm</span>
                </h2>
              </div>
              <div class="panel-body">
                <div class="row mb30">
                  <div class="col-sm-12 center">
                    <p>こちらの内容で登録します。内容に問題無ければ登録完了を押してください。</p>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-sm-3 col-md-3 control-label">
                    会社名・団体名
                  </label>
                  <div class="col-sm-6 col-md-4">
                    <p class="register_chk">
                      {{ user.organization_name }}
                    </p>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-sm-3 col-md-3 control-label">
                    本社・支社・支店・研究所
                  </label>
                  <div class="col-sm-6 col-md-4">
                    <p class="register_chk">
                      {{ user.branch_office }}
                    </p>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-sm-3 col-md-3 control-label">
                    部署
                  </label>
                  <div class="col-sm-6 col-md-4">
                    <p class="register_chk">
                      {{ user.department_name }}
                    </p>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-sm-3 col-md-3 control-label">
                    お名前
                  </label>
                  <div class="col-sm-4 col-md-4">
                    <p class="register_chk">
                      {{ user.last_name }} {{ user.first_name }}
                    </p>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-sm-3 col-md-3 control-label">
                    ふりがな
                  </label>
                  <div class="col-sm-4 col-md-4">
                    <p class="register_chk">
                      {{ user.last_name_jp }} {{ user.first_name_jp }}
                    </p>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-sm-3 col-md-3 control-label">
                    郵便番号
                  </label>
                  <div class="col-sm-3 col-md-3">
                    <p class="register_chk">
                      {{ user.zip_code }}
                    </p>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-sm-3 col-md-3 control-label">
                    住所
                  </label>
                  <div class="col-sm-8 col-md-8">
                    <p class="register_chk">
                      {{ user.address }}
                    </p>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-sm-3 col-md-3 control-label">
                    建物名など
                  </label>
                  <div class="col-sm-6 col-md-4">
                    <p class="register_chk">
                      {{ user.building_address }}
                    </p>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-sm-3 col-md-3 control-label">
                    電話番号
                  </label>
                  <div class="col-sm-6 col-md-4">
                    <p class="register_chk">
                      {{ user.phone_number }}
                    </p>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-sm-3 col-md-3 control-label">
                    メールアドレス(ログインID)
                  </label>
                  <div class="col-sm-7 col-md-7">
                    <p class="register_chk">
                      {{ user.email }}
                    </p>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-sm-3 col-md-3 control-label">
                    パスワード
                  </label>
                  <div class="col-sm-6 col-md-4">
                    <p class="register_chk">
                      セキュリティのため表示しません
                    </p>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-sm-3 col-md-3 control-label">
                    その他
                  </label>
                  <div class="col-sm-9 col-md-9">
                    <p class="register_chk others">
                      {{ user.others }}
                    </p>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-sm-3 col-md-3 control-label">
                    利用規約同意確認
                  </label>
                  <div class="col-sm-9">
                    <p class="register_chk">
                      同意する
                    </p>
                  </div>
                </div>

                <ul class="actionVertical clearfix">
                  <li>
                    <button type="submit" class="btn btn01">
                      登録完了
                    </button>
                  </li>
                  <li>
                    <button type="button" class="btn btn02" @click="goToRegister">
                      戻る
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </main>
</template>

<script>
import { registerApi } from '../../services/api'
import store from '../../store'
import { actions as actionsApp } from '../../store/modules/app'

export default {
  name: 'RegisterConfirm',
  data() {
    return {
      user: {}
    }
  },
  mounted() {
    this.user = this.$store.getters.userRegister
    if (Object.keys(this.user).length === 0) {
      this.$router.push({ name: 'login' })
    }
  },
  methods: {
    goToRegister() {
      this.$router.push({
        name: 'Register',
        params: {
          token: this.user.token,
          privacy_agreement: true
        }
      })
    },
    async register() {
      await store.dispatch(actionsApp.SET_LOADING, { active: true })
      const res = await registerApi({ ...this.user })
      await store.dispatch(actionsApp.SET_LOADING, { active: false })
      if (res.status === 201) {
        await this.$router.push({ name: 'RegisterSuccess' })
      } else {
        console.log('error', res.data)
      }
    }
  }
}
</script>

<style scoped>
.others {
  white-space: pre-line;
}
</style>
